.home-cy {
  width: 100%;
  height: 80%;
  position: absolute;
  left: 0;
  top: 10;
  padding-bottom: 3%;
  background-color: #282c34;
}

.MuiSvgIcon-root {
  color: white;
}

.tippy-tooltip {
  font-size: 16px !important;
}

#div-yesnotoggle {
  width: 200px;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
}
#div-notext {
  display: inline-block;
  width: 90px;
}
#div-switch {
  display: inline-block;
  width: 60px;
  vertical-align: middle;
}
#div-yestext {
  display: inline-block;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  display: none;
}

.inline {
  text-align: justify;
}